import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Clips = ({
  data: {
    allContentfulClip: { edges }
  }
}) => (
  <Layout>
    <SEO title="Clips" ogUrl="/clips" />

    <section className="wrapper style2 alt">
      <div className="inner">
        <header className="major">
          <p>Clips</p>
          <h2>Some of my published work</h2>
        </header>

        <section className="timeline">
          {edges.map(edge => {
            const { node } = edge;
            const { date, title, publication, link, post } = node;

            return (
              <section key={edge.node.id}>
                <header>
                  <p>{date}</p>
                  <h4>{title}</h4>
                  <h5>{publication}</h5>
                </header>

                <div className="content">
                  {documentToReactComponents(node.post.json)}
                  <ul className="actions">
                    <li>
                      <a href={link} className="button">
                        Read More
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
            );
          })}
        </section>
      </div>
    </section>
  </Layout>
);

Clips.propTypes = {
  data: PropTypes.object.isRequired
};

export default Clips;

export const pageQuery = graphql`
  query {
    allContentfulClip(sort: { order: DESC, fields: date }) {
      edges {
        node {
          date(formatString: "MM.YY")
          title
          publication
          post {
            json
          }
          link
        }
      }
    }
  }
`;
